<template>
  <div class="container">
    <div class="header">
      <div class="wrap">
        <div class="headerTop clearfloat">
          <div class="fr">
            <div class="headerTopInner headerTopSearch">
              <el-input placeholder="请输入关键词" v-model="searchTxt" size="mini" class="input-with-select">
                <el-button slot="append" size="mini" icon="el-icon-search" @click="handleSearch"></el-button>
              </el-input>
            </div>
            <div class="headerTopInner headerTopJt">
              <a target="_block" href="https://www.ghatg.com/">公航旅集团官网</a>
            </div>
          </div>
        </div>
        <div class="headerBox clearfloat">
          <div class="headerLogo fl">
            <a :href="`${apiName}`"><img src="../assets/img/logo_xin.png" alt=""></a>
          </div>
          <div class="nav_box fr">
            <ul class="clearfloat">
              <li :class="{'active':active == 0}">
                <div class="nav_grop">
                  <h2><router-link to="/">首页</router-link></h2>
                </div>
              </li>
              <li v-for="(item, index) in navListN" :key="index" :class="{'active':active == item.id}">
                <div
                  class="nav_grop" 
                  @mouseenter="navShow(item.id)"
                  @mouseleave="navClose()">
                  <h2><a :href="`${apiName}${item.url}`">{{item.name}}</a></h2>
                  <el-collapse-transition>
                    <div class="nav_down" v-show="navDown == item.id">
                      <div class="nav_down_wrap clearfloat" v-show="item.group_list.length != 0">
                        <p v-for="(item1, index1) in item.group_list" :key="index1">
                          <span class="nav_down_line" v-if="index1 !== 0"></span>
                          <a :href="item1.url && item1.url != '' ? `${apiName}${item1.url}` : `${apiName}list?id=${item1.id}&navName=${item.name}&groupName=${item1.name}`" v-if="item1.have_list == 0 && item1.is_link !== 1">{{item1.name}}</a>
                          <a :href="item1.url && item1.url != '' ? `${apiName}${item1.url}` : `${apiName}detail?id=${item1.id}&navName=${item.name}&groupName=${item1.name}&noList=1`" v-if="item1.have_list == 1 && item1.is_link !== 1">{{item1.name}}</a>
                          <a :href="`${apiName}${item1.url}`" target="_blank" v-if="item.name == '核心成员'">{{item1.name}}</a>
                          <a :href="item1.link_url" target="_blank" v-if="item1.is_link === 1">{{item1.name}}</a>
                        </p>
                      </div>
                    </div>
                  </el-collapse-transition>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="main">
      <router-view></router-view>
    </div>
    <div class="footer">
      <div class="footerWrap">
        <div class="wrap">
          <el-row :gutter="30">
            <el-col :span="20">
              <div class="footerContent">
                <p>
                  地址:甘肃省兰州市城关区农民巷8号之1号盛达金融大厦
                </p>
                <p>
                  <span> 电话:0931-8839098</span>   <span>传真:0931—8637957</span>
                </p>
                <p>
                  <span>E-mail: gsjrzb@ghatg.com</span>   <span>邮政编码:730030</span>
                </p>
                <!-- <p>
                  京公网安备11040102100085号京ICP备05050879号
                </p> -->
              </div>
            </el-col>
            <el-col :span="4">
              <div class="footerRight">
                <div class="footerCont">
                  <div class="footerRightCont">
                    <img src="../assets/img/ewm.jpg" alt="">
                    <p>扫码关注我们</p>
                  </div>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
      <div class="footerBotttom">
        <p>
          版权所有:甘肃省金融资本集团有限公司  备案序号：陇ICP备16002032号-2 <br>
          甘公网安备 62010202000850号
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  data(){
    return {
      searchTxt: '',
      active: 0,
      navListN: [
        {
            "id":1,
            "name":"公司概况",
            "isIndex":0,
            "url": 'company',
            "group_list":[
                {
                    "id":1,
                    "name":"股东背景",
                    "nav_id":2,
                    "have_list":1,
                    "url": 'company',
                    "create_time":"2020-12-22T07:10:13.037Z"
                },
                {
                    "id":4,
                    "name":"董事长寄语",
                    "nav_id":2,
                    "have_list":1,
                    "create_time":"2020-12-22T07:10:41.071Z",
                    "url": 'company?t=1'
                },
                {
                    "id":2,
                    "name":"关于我们",
                    "nav_id":2,
                    "have_list":0,
                    "create_time":"2020-12-22T07:10:23.364Z",
                    "url": 'company?t=2'
                },
                {
                    "id":2,
                    "name":"管理团队",
                    "nav_id":2,
                    "have_list":0,
                    "create_time":"2020-12-22T07:10:23.364Z",
                    "url": 'company?t=3'
                },
                {
                    "id":2,
                    "name":"企业文化",
                    "nav_id":2,
                    "have_list":0,
                    "create_time":"2020-12-22T07:10:23.364Z",
                    "url": 'company?t=4'
                },
                {
                    "id":2,
                    "name":"公司荣誉",
                    "nav_id":2,
                    "have_list":0,
                    "create_time":"2020-12-22T07:10:23.364Z",
                    "url": 'company?t=5'
                },
            ]
        },
        {
            "id":2,
            "name":"新闻中心",
            "isIndex":0,
            "url": 'news',
            "group_list":[
                {
                    "id":7,
                    "name":"公司要闻",
                    "nav_id":3,
                    "have_list":0,
                    "create_time":"2020-12-22T07:11:14.391Z",
                    "url": 'news'
                },
                {
                    "id":8,
                    "name":"子公司动态",
                    "nav_id":3,
                    "have_list":0,
                    "create_time":"2020-12-22T07:11:41.023Z",
                    "url": 'news?t=1'
                },
                {
                    "id":8,
                    "name":"行业动态",
                    "nav_id":3,
                    "have_list":0,
                    "create_time":"2020-12-22T07:11:41.023Z",
                    "url": 'news?t=2'
                },
                {
                    "id":8,
                    "name":"员工风采",
                    "nav_id":3,
                    "have_list":0,
                    "create_time":"2020-12-22T07:11:41.023Z",
                    "url": 'news?t=3'
                },
                {
                    "id":8,
                    "name":"媒体聚焦",
                    "nav_id":3,
                    "have_list":0,
                    "create_time":"2020-12-22T07:11:41.023Z",
                    "url": 'news?t=4'
                },
                {
                    "id":8,
                    "name":"视频集锦",
                    "nav_id":3,
                    "have_list":0,
                    "create_time":"2020-12-22T07:11:41.023Z",
                    "url": 'news?t=5'
                },
                {
                    "id":8,
                    "name":"公示公告",
                    "nav_id":3,
                    "have_list":0,
                    "create_time":"2020-12-22T07:11:41.023Z",
                    "url": 'news?t=6'
                },
            ]
        },
        {
            "id":3,
            "name":"公司业务",
            "isIndex":0,
            "url": 'business',
            "group_list":[
              {
                  "id":8,
                  "name":"融资担保",
                  "nav_id":3,
                  "have_list":0,
                  "create_time":"2020-12-22T07:11:41.023Z",
                  "url": 'business?t=0'
              },
              {
                  "id":8,
                  "name":"小额贷款",
                  "nav_id":3,
                  "have_list":0,
                  "create_time":"2020-12-22T07:11:41.023Z",
                  "url": 'business?t=1'
              },
              // {
              //     "id":8,
              //     "name":"产权交易",
              //     "nav_id":3,
              //     "have_list":0,
              //     "create_time":"2020-12-22T07:11:41.023Z",
              //     "url": 'business?t=2'
              // },
              {
                  "id":8,
                  "name":"供应链业务",
                  "nav_id":3,
                  "have_list":0,
                  "create_time":"2020-12-22T07:11:41.023Z",
                  "url": 'business?t=2'
              },
              {
                  "id":8,
                  "name":"资产管理",
                  "nav_id":3,
                  "have_list":0,
                  "create_time":"2020-12-22T07:11:41.023Z",
                  "url": 'business?t=3'
              },
              {
                  "id":8,
                  "name":"典当业务",
                  "nav_id":3,
                  "have_list":0,
                  "create_time":"2020-12-22T07:11:41.023Z",
                  "url": 'business?t=4'
              },
              {
                  "id":8,
                  "name":"私募基金",
                  "nav_id":3,
                  "have_list":0,
                  "create_time":"2020-12-22T07:11:41.023Z",
                  "url": 'business?t=5'
              },
              {
                  "id":8,
                  "name":"金融仓储",
                  "nav_id":3,
                  "have_list":0,
                  "create_time":"2020-12-22T07:11:41.023Z",
                  "url": 'business?t=6'
              },
              {
                  "id":8,
                  "name":"融资租赁",
                  "nav_id":3,
                  "have_list":0,
                  "create_time":"2020-12-22T07:11:41.023Z",
                  "url": 'business?t=7'
              },
              // {
              //     "id":8,
              //     "name":"要素交易",
              //     "nav_id":3,
              //     "have_list":0,
              //     "create_time":"2020-12-22T07:11:41.023Z",
              //     "url": 'business?t=8'
              // },
              {
                  "id":8,
                  "name":"商业保理",
                  "nav_id":3,
                  "have_list":0,
                  "create_time":"2020-12-22T07:11:41.023Z",
                  "url": 'business?t=8'
              },
            ]
        },
        {
            "id":4,
            "name":"党建园地",
            "isIndex":0,
            "url": 'party',
            "group_list":[
                {
                    "id":13,
                    "name":"党建要闻",
                    "nav_id":4,
                    "have_list":0,
                    "create_time":"2020-12-22T07:12:55.466Z",
                    "url": 'party'
                },
                {
                    "id":14,
                    "name":"政策法规",
                    "nav_id":4,
                    "have_list":0,
                    "create_time":"2020-12-22T07:12:55.466Z",
                    "url": 'party?t=1'
                },
                {
                    "id":15,
                    "name":"群团工作",
                    "nav_id":4,
                    "have_list":0,
                    "create_time":"2020-12-22T07:12:55.466Z",
                    "url": 'party?t=2'
                },
                {
                    "id":16,
                    "name":"纪检监察",
                    "nav_id":4,
                    "have_list":0,
                    "create_time":"2020-12-22T07:13:02.484Z",
                    "url": 'party?t=3'
                },
                // {
                //     "id":17,
                //     "name":"党建融入生产经营",
                //     "nav_id":4,
                //     "have_list":0,
                //     "create_time":"2020-12-22T07:13:02.484Z",
                //     "url": 'party?t=4'
                // },
            ]
        },
        {
            "id":5,
            "name":"人力资源",
            "isIndex":0,
            "url": 'hr',
            "group_list":[
                {
                    "id":20,
                    "name":"人才战略",
                    "nav_id":5,
                    "have_list":0,
                    "create_time":"2020-12-22T07:14:03.529Z",
                    "url": 'hr'
                },
                {
                    "id":20,
                    "name":"人才引进",
                    "nav_id":5,
                    "have_list":0,
                    "create_time":"2020-12-22T07:14:03.529Z",
                    "url": 'hr?t=1'
                },
                {
                    "id":20,
                    "name":"人才培训",
                    "nav_id":5,
                    "have_list":0,
                    "create_time":"2020-12-22T07:14:03.529Z",
                    "url": 'hr?t=2'
                },
                {
                    "id":20,
                    "name":"信息公示",
                    "nav_id":5,
                    "have_list":0,
                    "create_time":"2020-12-22T07:14:03.529Z",
                    "url": 'hr?t=3'
                },
            ]
        },
        {
            "id":6,
            "name":"信息公开",
            "isIndex":0,
            "url": 'information',
            "group_list":[
                {
                    "id":20,
                    "name":"重大事项",
                    "nav_id":5,
                    "have_list":0,
                    "create_time":"2020-12-22T07:14:03.529Z",
                    "url": 'information'
                },
                {
                    "id":20,
                    "name":"经营管理",
                    "nav_id":5,
                    "have_list":0,
                    "create_time":"2020-12-22T07:14:03.529Z",
                    "url": 'information?t=1'
                },
                {
                    "id":20,
                    "name":"重大人事信息",
                    "nav_id":5,
                    "have_list":0,
                    "create_time":"2020-12-22T07:14:03.529Z",
                    "url": 'information?t=2'
                },
                {
                    "id":20,
                    "name":"社会责任",
                    "nav_id":5,
                    "have_list":0,
                    "create_time":"2020-12-22T07:14:03.529Z",
                    "url": 'information?t=3'
                },
            ]
        },
      ],
      navDown: 0,

    }
  },
  mounted(){
    this.getPath()
  },
  created(){

  },
  watch:{
    '$route':'getPath'
  },
  methods: {
    handleSearch() {
      var _this = this
      if (!_this.searchTxt || _this.searchTxt == '') {
        this.$message({
          message: '请输入关键词',
          type: 'warning'
        })
      } else {
        window.location.href = `${this.apiName}search?title=${_this.searchTxt}`
        // let listUrl = this.$router.resolve({name: '/search', params: {title: _this.searchTxt}})
        // console.log('listUrl :>> ', listUrl)
        // window.open( listUrl.href, '_blank' )
        // this.$router.push({path: "/search", query: {title: _this.searchTxt}})
        // window.location.href = `/search?title=${_this.searchTxt}`
        // window.open(`/search/${_this.searchValue}`)
      }
    },
    // 二级导航展开
    navShow(d){
      let _this = this
      // _this[d] = true
      _this.navDown = d
    },
    // 二级导航收起
    navClose(){
      let _this = this
      // _this[d] = false
      _this.navDown = 0
    },
    haddleNav(index, params){
      var _this = this

      _this.active = index
      _this.$router.push(params.url)
    },
    getPath(){
      var _this = this
      console.log('_this.$route.name :>> ', _this.$route.name);
      _this.active = _this.$route.meta.index
      console.log('_this.active :>> ', _this.active);
    }
  }
}
</script>
<style>
.header {
  padding-top: 15px;
  text-align: center;
  /* background-image: url(../assets/img/header_bg.png); */
  background-size: 100% auto;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
}
.headerLogo {
  margin-top: -3px;
  /* margin-bottom: 20px; */
}
.headerLogo img {
  /* width: 360px; */
  height: 52px;
  width: auto;
  vertical-align: top;
}
.headerNavList {
  padding-bottom: 18px;
}
.headerNavList li {
  float: left;
  text-align: center;
  width: 12.5%;
  position: relative;
  line-height: 20px;
  font-size: 16px;
}
.headerNavList li:last-child .line {
  display: none;
}
.headerNavTxt {
  display: inline-block;
  min-width: 45px;
  color: #000;
  letter-spacing: 4px;
  cursor: pointer;
}
.headerNavTxt:hover {
  color: #BC8F68;
}
.headerNavList .line {
  position: absolute;
  width: 12px;
  height: 2px;
  background-color: #d3d3d3;
  top: 50%;
  margin-top: -1px;
  right: -6px;
}
.headerNavList li.active .headerNavTxt {
  font-weight: bold;
  color: #BC8F68;
}
.headerNavList li.active::after {
  display: block;
  width: 46px;
  height: 18px;
  content: "";
  position: absolute;
  bottom: -18px;
  left: 50%;
  margin-left: -23px;
  background-image: url(../assets/img/header_nav_ico.png);
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
}
.main {
  min-height: 500px;
}
.footer {
  background: #282E31;
  color: #9D9D9D;
}
.footerWrap {
  padding: 60px 0 40px;
  text-align: left;
}
.footerTitle {
  font-size: 18px;
  line-height: 22px;
  color: #fff;
  margin-bottom: 30px;
}
.footerTitle .line {
  width: 26px;
  height: 2px;
  background: #fff;
  margin-top: 8px;
}
.footerLeftTxt {
  display: inline-block;
  min-width: 56px;
  vertical-align: middle;
}
.footerLeftTxt a {
  color: #9D9D9D;
}
.footerLeftTxt a:hover {
  color: #fff;
}
.footerLeftLine {
  display: inline-block;
  margin: 0 20px;
  vertical-align: middle;
}
.footerLeft .footerCont {
  line-height: 34px;
}
.footerCenterIco {
  display: inline-block;
  vertical-align: middle;
  width: 36px;
  height: 36px;
  margin-right: 4px;
  line-height: 36px;
}
.footerCenterIco img {
  width: 90%;
  height: auto;
  vertical-align: middle;
}
.footerCenterTxt {
  display: inline-block;
  vertical-align: middle;
  line-height: 22px;
}
.footerCenterTxt h3 {
  font-size: 16px;
  font-weight: bold;
  color: #FFFFFF;
}
.footerCenterTxt p {
  font-size: 14px;
}
.footerCenterCont {
  padding-top: 14px;
}
.footerCenterInnerF {
  margin-right: 30px;
}
.footerRightCont {
  display: inline-block;
  vertical-align: top;
  text-align: center;
}
.footerRightCont img {
  width: 116px;
  height: auto;
  margin-top: -6px;
}
.footerRightCont p {
  color: #BFC1C2;
  margin-top: 10px;
}
.footerBotttom {
  color: #fff;
  line-height: 30px;
  text-align: center;
  padding-bottom: 30px;
  /* border-top: 1px solid #9D9D9D; */
}
.nav_box {
    /* height: 38px; */
    /* background-color: #1970b9; */
    line-height: 38px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  .nav_box ul li {
    height: 60px;
    line-height: 60px;
    float: left; 
    /* width: 96px;  */
    color: #fff;
    text-align: center;
    /* position: relative; */
    margin-left: 15px;
  }
  /* .nav_box ul li a {
    display: block;
  } */
  .nav_grop {
    /* width: 82px; */
    height: 100%;
    /* margin: 5px auto 0; */
    /* position: relative; */
  }
  .nav_box ul li h2 {
    text-align: center;
    /* line-height: 50px; */
    height: 100%;
    font-size: 20px;
    /* border-radius: 4px; */
    /* border-bottom-left-radius: 0;
    border-bottom-right-radius: 0; */
    overflow: hidden;
    /* border: 1px solid #1970b9; */
    position: relative;
  }
  .nav_box ul li h2 a {
    display: block;
    color: #000;
    padding: 0 10px;
  }
  .nav_grop:hover h2 {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  /* .nav_grop:hover h2 a {
    color: #1970b9;
    background-color: #fff;
    font-weight: 600;
  } */
  /* .nav_box ul li:hover h2 a,
  .nav_box ul li.active h2 a {
    color: #fff;
    background-color: #004097;
    font-weight: 600;
  }.nav_box ul li:hover h2 a::after,
  .nav_box ul li.active h2 a::after {
    display: block;
    position: absolute;
    content: "";
    width: 100%;
    height: 4px;
    left: 0;
    bottom: 0;
    background-color: #FABF01;
  } */
  .nav_box ul li:hover h2 a {
    color: #fff;
    background-color: #004097;
    font-weight: 600;
  }
  .nav_box ul li:hover h2 a::after {
    display: block;
    position: absolute;
    content: "";
    width: 100%;
    height: 4px;
    left: 0;
    bottom: 0;
    background-color: #FABF01;
  }
  .nav_down {
    position: absolute;
    width: 100%;
    left: 0;
    top: 110px;
    z-index: 55;
    text-align: right;
    background-color: #004097;
    line-height: 36px;
  }
  .nav_down_wrap {
    width: 1200px;
    margin: 0 auto;
    padding: 10px 0;
    /* border: 1px solid rgb(35, 96, 176); */
    border-top: none;
    /* background-color: #fff; */
    box-sizing: border-box;
  }
  .nav_box ul li:nth-child(2) .nav_down_wrap {
    padding-right: 24px;
  }
  .nav_box ul li:nth-child(3) .nav_down_wrap {
    padding-right: 4px;
  }
  /* .nav_box ul li:nth-child(5) .nav_down_wrap {
    padding-right: 65px;
  } */
  .nav_down_line {
    display: inline-block;
    width: 1px;
    height: 24px;
    vertical-align: middle;
    background-color: #fff;
    margin: 0 12px;
  }
  .nav_down p {
    display: inline-block;
    line-height: 36px;
    text-align: center;
    font-size: 16px;
  }
  .nav_down p a {
    color: #fff;
    vertical-align: middle;
  }
  .nav_down p a:hover {
    /* color: #1970b9; */
    text-decoration: underline;
  }
  .header_hd {
    height: 34px;
    line-height: 34px;
    /* background-image: url("../assets/img/st_03.jpg"); */
    background-repeat: repeat-x;
    background-size: auto 100%;
    padding: 0 25px;
    font-size: 12px;
  }
  .header_top {
    padding: 10px;
  }
  .mobile {
    display: block;
    height: 20px;
    line-height: 20px;
    padding: 0 8px;
    border: 1px solid #dedede;
    margin-top: 6px;
    font-size: 12px;
    font-weight: 600;
  }

  .mobile:hover {
    color: #d60f0f;
  }
  .user_input {
    display: inline-block;
    width: 120px;
  }
  .user_l {
    display: inline-block;
    margin-left: 10px;
  }
  .user_sure {
    margin: 0 4px 0 6px;
    height: 26px;
    line-height: 26px;
    padding: 0 10px;
    border: 1px solid #b6b6b6;
    cursor: pointer;
    vertical-align: top;
    margin-top: 3px;
    background-color: #e9e9e9;
    border-radius: 2px;
  }
  .user_sure:hover {
    background-color: #dddddd;
  }
  .user_forget:hover {
    color: #d60f0f;
    text-decoration: underline;
  }
  .mail_dsz {
    display: inline-block;
    height: 20px;
    line-height: 20px;
    padding: 0 8px;
    border: 1px solid #dedede;
    margin-top: 6px;
    font-size: 12px;
    color: #d60f0f;
    margin-left: 30px;
  }
  .mail_dsz:hover {
    border-color: #d60f0f;
  }
  .user_wrap {
    display: inline-block;
  }
  .headerTop {
    height: 36px;
    line-height: 36px;
  }
  .headerTopInner {
    display: inline-block;
  }
  .headerTopSearch {
    /* width: 166px; */
    margin-right: 20px;
  }
  .headerTopJt a {
    color: #666;
  }
  .headerTopJt a:hover {
    color: #004097;
  }
  .footerContent {
    color: #fff;
    line-height: 34px;
  }
  .footerContent span {
    display: inline-block;
    margin-right: 20px;
  }
</style>